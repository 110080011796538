.App {
    // background-color: green;
    height: 100vh;
    width: 100vw;
    // display: block;
    // padding: 0;
    // margin: 0;

    font-family: "Roboto";
}

body {
    margin: 0;
    padding: 0;
}

// Colors
$primary: #83101f;
$dark: #363636;
$light: #d5d5d5;
$white: #fff;
$tulpe: #f0b;

.text-light {
    color: $light;
}

@import "custom";
@import "pages";
@import "organisationSelect";