// $theme-colors: (
//     "primary": #83101f,
//     "dark": #363636,
//     "white": #fff,
//     "tulpe": #f0b,
// );


/* elements */

#dashboard-nav {
    width: 320px,
}

.device-card-header {
    display: flex;
    align-items: center;
}

.devices-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.devices-state-overview {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.device-card {
    padding: 24px;
    background-color: gainsboro;
    border-radius: 12.5%;

    cursor: pointer;
}

.device-ok::before {
    // .device-ok {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    transform: translateY(-25%);
    border-radius: 50%;
    margin-right: 16px;
    background-color: green;
}

.device-warning::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    transform: translateY(-25%);
    border-radius: 50%;
    margin-right: 16px;
    background-color: orange;
}

.device-alarm::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    transform: translateY(-25%);
    border-radius: 50%;
    margin-right: 16px;
    background-color: red;
}

.device-data-expired::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    transform: translateY(-25%);
    border-radius: 50%;
    margin-right: 16px;
    background-color: blue;
}

.device-unknown::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    transform: translateY(-25%);
    border-radius: 50%;
    margin-right: 16px;
    background-color: gray;
}

// .nbx-header::before {
//     content: '';
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     transform: translateY(-25%);
//     border-radius: 50%;
//     margin-right: 16px;
//     background-color: #83101f;
// }

// .ubx-header::before {
//     content: '';
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     transform: translateY(-25%);
//     border-radius: 50%;
//     margin-right: 16px;
//     background-color: green;
// }

.custom-navbar-item {
    background-color: $primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
    display: block;
    width: 290px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    margin-left: 16px;
}

.custom-navbar-item-icon {
    height: auto;
    width: 40px;
    color: #fff;
}

.custom-navbar-item-caption {
    text-decoration: none;
    padding-left: 20px;
    color: #fff;
}

// .leaflet-container {
//     height: 600px;
//     width: 100%;
// }

.floating-button {
    position: absolute;
    bottom: 50px;
    right: 50px;
}

.notification-table-header {
    display: flex;
    align-items: center;
}

.login-modal {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: wheat;
}

.icon-green {
    filter: sepia(100%) hue-rotate(50deg) brightness(80%) saturate(420%);
}

.icon-orange {
    filter: sepia(100%) hue-rotate(355deg) brightness(80%) saturate(420%);
}

.icon-red {
    filter: sepia(100%) hue-rotate(305deg) brightness(80%) saturate(420%);
}

.wtf {
    filter: sepia(100%) hue-rotate(150deg) brightness(80%) saturate(420%);
}

/* import bootstrap to set changes */
// @import "~bootstrap/scss/bootstrap";