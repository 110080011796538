.organisation-select-page {
    position: relative;
    padding: 48px;
    // height: 100%;
    // width: 100%;
}

.organisation-select-logout-button {
    position: absolute;
    bottom: 24px;
    right: 24px + 48px;
}