// whole page
.dashboard-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dashboard-page-sidebar {
    // height: 100%;
    background-color: $dark;
    // flex-grow: 1;
}

.dashboard-page-main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.dashboard-page-main-header {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 120px;
    align-items: center;

    background-color: $dark;
    margin: 0;
}

.dashboard-header-image {
    max-height: 100px;
    width: auto;
    border-radius: 50%;
}

.dashboard-page-main-content {
    position: relative;
    flex-grow: 1;
    overflow: auto;
    height: 100%;
}

.dashboard-body {
    padding: 24px;
}

.dashboard-page-main-footer {
    display: flex;
    justify-content: center;

    padding: 1em 0;
    width: 100%;

    background-color: $dark;
    font-size: 24px;
}

.login-page {
    height: 100%;
    width: 100%;
    display: flex;
}

.login-page-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}